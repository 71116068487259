export enum WorkingDayEnumsTH {
  จันทร์,
  อังคาร,
  พุธ,
  พฤหัสบดี,
  ศุกร์,
  เสาร์,
  อาทิตย์,
}

export enum WorkingDayEnumsShort {
  จ,
  อ,
  พ,
  พฤ,
  ศ,
  ส,
  อา,
}

export enum PromiseStatusEnum {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}
