import jwtDefaultConfig from '../utility/constants/jwtDefaultConfig';
import axios from './axios';

export interface UserAuthInterface {
  userName: string;
  password: string;
}

export interface ChangePasswordInterface {
  oldPassword: string;
  newPassword: string;
}
export interface ResetPasswordInterface {
  password: string;
  userId: string;
}
export interface PasswordInterface {
  password: string;
}

const authServices = {
  login: (payload: UserAuthInterface) =>
    axios.post(jwtDefaultConfig.loginEndpoint, payload),
  getProfile: () => axios.get(jwtDefaultConfig.profileEndpoint),
  changeAdminPassword: (payload: ChangePasswordInterface) =>
    axios.patch(jwtDefaultConfig.changePasswordEndpoint, payload),
  resetAdminPassword: (payload: ResetPasswordInterface) =>
    axios.patch(`${jwtDefaultConfig.resetPasswordEndpoint}/${payload.userId}`, {
      password: payload.password,
    }),
};
export default authServices;
