import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { WorkingDayEnumsShort, WorkingDayEnumsTH } from './enums';
dayjs.extend(buddhistEra);
export const setCookie = (key: string, value: string) => {
  try {
    Cookies.set(key, value);
  } catch (error) {
    console.error(error);
  }
};

export const getCookie = (key: string) => {
  try {
    const value = Cookies.get(key);
    return value !== undefined ? value : null;
  } catch (error) {
    console.log(error);
  }
};

export const removeCookie = (key: string) => {
  try {
    Cookies.remove(key);
  } catch (error) {
    console.error(error);
  }
};

export const convertToDate = (date: string, option?: string) => {
  switch (option) {
    case 'time':
      return date ? dayjs(date).format('DD/MM/BBBB, HH:mm น.') : '-';
    default:
      return date ? dayjs(date).format('DD/MM/BBBB') : '-';
  }
};

export const formatNumberSCI = (value: number): string => {
  const formatting = 'en-US';
  return new Intl.NumberFormat(formatting, {}).format(value);
};

export const convertToFullDate = (date: string) => {
  if (!date) return '-';
  return date ? dayjs(date).locale('th').format('DD MMMM BBBB') : '-';
};
export const convertToTime = (date: string | Date) => {
  return dayjs(date).locale('th').format('HH:mm');
};

export const calculateAgeYear = (userBirthDate: string) => {
  const toDay = dayjs();
  return toDay.diff(userBirthDate ?? toDay, 'year');
};

export const calculateAge = (birthDate: string) => {
  let age = '';

  const birthYear = Number(dayjs(birthDate).format('YYYY'));
  const birthMonth = Number(dayjs(birthDate).format('MM'));
  const birthDay = Number(dayjs(birthDate).format('DD'));

  let currentYear = Number(dayjs().format('YYYY'));
  let currentMonth = Number(dayjs().format('MM'));
  const currentDay = Number(dayjs().format('DD'));

  age += currentYear - birthYear + ' ปี ';

  if (currentMonth < birthMonth) {
    age += currentMonth - birthMonth + 12 + ' เดือน ';
    currentYear = currentYear - 1;
  } else {
    age += currentMonth - birthMonth + ' เดือน ';
  }

  if (currentDay < birthDay) {
    age += currentDay - birthDay + 30 + ' วัน ';
    currentMonth = currentMonth - 1;
  } else {
    age += currentDay - birthDay + ' วัน ';
  }

  return birthDate ? age : '-';
};

export const workDayToThaiShort = (workingDay: Array<number>) => {
  const workDays = (
    Object.keys(WorkingDayEnumsShort) as Array<
      keyof typeof WorkingDayEnumsShort
    >
  ).map((_, index) => {
    return WorkingDayEnumsShort[index];
  });
  const workDayInThai = workDays.filter((item) => item);
  return workingDay.map((item) => workDayInThai[item - 1] ?? '-');
};

export const workDayToThaiFull = (workingDay: Array<number>) => {
  const workDays = (
    Object.keys(WorkingDayEnumsTH) as Array<keyof typeof WorkingDayEnumsTH>
  ).map((_, index) => {
    return WorkingDayEnumsTH[index];
  });
  const workDayInThai = workDays.filter((item) => item);
  return workingDay?.map((item) => workDayInThai[item - 1] ?? '-');
};

export const columnById = (id: number, enumType: any) => {
  const columnList = (
    Object.keys(enumType) as Array<keyof typeof enumType>
  ).map((_, index) => {
    return enumType[index];
  });
  const column = columnList.filter((item) => item);
  return column[id - 1];
};
// Get Time Block in Half Hours Step .

export interface TimeBlockOptionsInterface {
  label: string;
  value: string;
}
export const getTimeBlocks = (): Array<TimeBlockOptionsInterface> => {
  const minutesInDay = 1440;
  const timeBlocksArr: Array<TimeBlockOptionsInterface> = [
    {
      label: '00:00',
      value: dayjs().startOf('day').hour(0).minute(0).format(),
    },
  ];
  for (let i = 30; i <= minutesInDay - 30; i += 30) {
    const halfHourInLoop = i / 60;
    // if (halfHourInLoop > 8 && halfHourInLoop < 21) {
    // }
    let formattedBlock = String(halfHourInLoop);
    const hour = formattedBlock.split('.')[0];
    const minute = i % 60 === 0 ? '00' : '30';
    formattedBlock = dayjs()
      .startOf('day')
      .hour(+hour)
      .minute(+minute)
      .format();

    const today = new Date();
    const timeString = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hour),
      Number(minute)
    );
    timeBlocksArr.push({
      label: timeString.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',

        hourCycle: 'h23',
      }),
      value: formattedBlock,
    });
  }
  return timeBlocksArr;
};

export const downloadStringAsFile = (data: string, filename: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
};

export const calculateVAT = (amount: number, vat: number): number => {
  const vatPercent = vat / 100;
  // base case
  if (!amount || amount === 0) {
    return 0;
  }
  const vatResult = amount * vatPercent;

  const amountIncludeVAT = amount + vatResult;
  return amountIncludeVAT;
};
