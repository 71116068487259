import { createContext, FC, useContext, useEffect, useState } from 'react';
import jwtDefaultConfig from '../constants/jwtDefaultConfig';

import useAlert from '../../utility/hooks/useAlert';
import authServices from '../../services/auth';
import { getCookie, removeCookie, setCookie } from '../../utility/utilities';
import { UserInterface } from '../../utility/types';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthProviderPropType {
  children: React.ReactNode;
}

interface LoginResponseInterface {
  accessToken: string;
  refreshToken: string;
}
interface AuthContextInterface {
  user: UserInterface | null;
  setUserLogin: (userLoginData: LoginResponseInterface) => void;
  logout: () => void;
  isUserLogin: boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  setUserLogin: () => {
    return;
  },
  logout: () => {
    return;
  },
  isUserLogin: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<AuthProviderPropType> = ({ children }) => {
  const { showErrorMessage } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<UserInterface | null>(null);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [mounted, setmounted] = useState<boolean>(false);

  useEffect(() => {
    checkAuth().then((login) => {
      if (login) {
        getUserProfile();
      } else {
        logout();
      }
    });
    setmounted(true);
  }, [location]);

  const logout = () => {
    setUser(null);
    removeCookie(jwtDefaultConfig.storageTokenKeyName);
    removeCookie(jwtDefaultConfig.storageRefreshTokenKeyName);
    setIsLogin(false);
  };

  const checkAuth = () => {
    return new Promise((resolve) => {
      const [accessToken, refreshToken] = [
        getCookie(jwtDefaultConfig.storageTokenKeyName),
        getCookie(jwtDefaultConfig.storageRefreshTokenKeyName),
      ];

      const checkIsLogin = !!accessToken && !!refreshToken;
      setIsLogin(checkIsLogin);
      resolve(checkIsLogin);
    });
  };
  const getUserProfile = async () => {
    try {
      const { status, data } = await authServices.getProfile();
      if (status === 200) {
        setUser(data.data.item);
      } else {
        throw Error('ไม่สามารถเรียกดูข้อมูลผู้ใช้ได้ กรุณาลองใหม่อีกครั้ง');
      }
    } catch (error) {
      showErrorMessage(
        error,
        'ไม่สามารถเรียกดูข้อมูลผู้ใช้ได้ กรุณาลองใหม่อีกครั้ง'
      );
      logout();
      navigate('/login');
    }
  };
  const setUserLogin = (userLoginData: LoginResponseInterface) => {
    setCookie(jwtDefaultConfig.storageTokenKeyName, userLoginData.accessToken);
    setCookie(
      jwtDefaultConfig.storageRefreshTokenKeyName,
      userLoginData.refreshToken
    );
    getUserProfile();
  };
  return (
    <AuthContext.Provider
      value={{ user, setUserLogin, logout, isUserLogin: isLogin }}
    >
      {mounted && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
