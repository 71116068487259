import useSwal from './useSwal';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { SweetAlertIcon } from 'sweetalert2';
interface ServerErrorResponse {
  statusCode: number;
  message: string;
}

const useAlert = () => {
  const swal = useSwal();

  const showSuccessMessage = (message: string) => {
    swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const showQuestionMessage = (message: string, text?: string) => {
    swal.fire({
      icon: 'question',
      title: message,
      text: text || '',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const showErrorMessage = (
    error: unknown,
    fallback = 'เกิดข้อผิดพลาดบางอย่างกรุณาลองใหม่อีกครั้ง'
  ) => {
    let errorMessage = fallback;

    switch (true) {
      case axios.isAxiosError(error):
        errorMessage =
          ((error as AxiosError).response as AxiosResponse<ServerErrorResponse>)
            ?.data?.message || fallback;
        break;

      case !!(error as Error).message:
        errorMessage = (error as Error).message;
        break;

      default:
        errorMessage = error as string;
        break;
    }

    swal.fire({
      icon: 'error',
      title: errorMessage,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const confirmCancel = (message: string, text?: string) => {
    return swal
      .fire({
        title: message + '?',
        text: 'คุณต้องการ' + message + ', ใช่หรือไม่ ?' || '',
        icon: 'warning',
        iconColor: 'grey',
        showCancelButton: true,
        confirmButtonText: `ใช่, ${message || ''}`,
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
        },
        buttonsStyling: false,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };
  const clinicDeleteAlert = () => {
    return swal
      .fire({
        title: 'ไม่สามารถลบบัญชีคลินิกได้ ?',
        text: 'เนื่องจากคุณมีรายการนัดหมายหรือการลงตารางงานอยู่ในระบบโดยคุณสามารถลบบัญชีผู้ใช้งานได้หลังจากยกเลิกหรือจัดการนัดหมายและตารางงานเรียบร้อยแล้ว',
        icon: 'warning',
        iconColor: 'red',
        // showCancelButton: true,
        confirmButtonText: 'เข้าใจแล้ว',
        customClass: {
          confirmButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
          cancelButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
        },
        buttonsStyling: false,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };
  const confirmDelete = (message: string, text?: string) => {
    return swal
      .fire({
        title: message + '?',
        text: 'คุณต้องการ' + message + ', ใช่หรือไม่ ?' || '',
        iconHtml: '<img src="/assets/images/trash.png" src="trash icon" />',
        iconColor: 'transparent',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonText: text ? text : `ใช่, ${message ?? ''}`,
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
        },
        buttonsStyling: false,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };
  const confirmReset = (message: string, text?: string) => {
    return swal
      .fire({
        title: message + '?',
        text: 'คุณต้องการ' + message + ', ใช่หรือไม่ ?' || '',
        iconHtml: '<img src="/assets/images/lock.png" src="lock icon" />',
        iconColor: 'transparent',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonText: text ? text : `ใช่, ${message ?? ''}`,
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
        },
        buttonsStyling: false,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };
  const confirmAccept = (message: string) => {
    return swal
      .fire({
        title: message + '?',
        text: 'คุณต้องการ' + message + ', ใช่หรือไม่ ?' || '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `ใช่, ${message ?? ''}`,
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn ms-1 btn-outline-dark bg-white text-dark',
        },
        buttonsStyling: false,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };
  const toast = (message: string, icon?: SweetAlertIcon) => {
    return swal
      .fire({
        toast: true,
        titleText: message,
        icon: icon || 'success',
        position: 'top-right',
        timer: 3000,
        showConfirmButton: false,
        timerProgressBar: true,
      })
      .then(({ isConfirmed }) => isConfirmed);
  };

  return {
    showSuccessMessage,
    showErrorMessage,
    showQuestionMessage,
    clinicDeleteAlert,
    confirmDelete,
    confirmCancel,
    confirmReset,
    confirmAccept,
    toast,
  };
};

export default useAlert;
