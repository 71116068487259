// ** Auth Endpoints
export default {
  loginEndpoint: '/auth/admin/login',
  refreshEndpoint: '/auth/admin/refresh',
  profileEndpoint: '/auth/admin/me',
  changePasswordEndpoint: '/auth/admin/change-password',
  resetPasswordEndpoint: '/admin/manage-users/reset-password',
  registerEndpoint: '/public/admin',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
};
